<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    class="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="false"
    mobile-breakpoint="960"
    app
    width="240"
    elevation="2"
    rounded
  >
    <!-- App Logo and Name -->
    <div class="drawer-header">
      <div class="logo-container">
        <v-img :src="icon" max-height="32" contain class="logo-image" />
        <!-- <h2 class="app-name">{{ $appName }}</h2> -->
      </div>
    </div>

    <!-- Navigation Menu -->
    <v-list
      nav
      class="drawer-list"
      flat
    >
      <div class="menu-section">
        <template v-for="(item, i) in computedItems">
          <!-- Group with Children -->
          <template v-if="item.children">
            <v-list-group
              :key="`group-${i}`"
              
              no-action
              class="menu-group"
            >
              <template v-slot:activator>
                <v-list-item dense class="menu-item">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="menu-title">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
                <!-- <v-badge
                  v-if="item.warning"
                  dot
                  color="warning"
                  class="ml-2"
                  offset-x="2"
                  offset-y="2"
                >
                  <template v-slot:badge>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on"></span>
                      </template>
                      <span>Attention needed</span>
                    </v-tooltip>
                  </template>
                </v-badge> -->
              </template>

              <v-list-item
                v-for="(child, j) in item.children"
                :key="`child-${j}`"
                :to="child.to"
                :disabled="child.disabled"
                link
                dense
                class="menu-child-item"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon small>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="menu-child-title">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>

          <!-- Single Item -->
          <v-list-item
            v-else
            :key="`item-${i}`"
            :to="item.to"
            :disabled="item.disabled"
            link
            dense
            class="menu-item"
          >
            <v-list-item-icon class="mr-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="menu-title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-badge
              v-if="item.warning"
              dot
              color="warning"
              class="ml-2"
              offset-x="2"
              offset-y="2"
            >
              <template v-slot:badge>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on"></span>
                  </template>
                  <span>Attention needed</span>
                </v-tooltip>
              </template>
            </v-badge>
          </v-list-item>
        </template>
      </div>
    </v-list>

    <!-- Footer -->
    <template v-slot:append>
      <div class="drawer-footer">
        <v-divider class="mb-3"></v-divider>
        <div class="text-caption text-center">
          <span class="copyright">{{ $appName }} &copy; {{ new Date().getFullYear() }}</span>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardCoreDrawer',
  data: () => ({
    icon: require('@/assets/favicon-192.png'),
    items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/auth/dashboard',
      },
      {
        icon: 'mdi-package-variant',
        title: 'Fulfill',
        to: '/auth/fulfill',
        scope: 'fulfill',
        hide_on_outsourcing: true,
        hide: process.env.VUE_APP_MODE !== 'fh',
      },
      {
        icon: 'mdi-warehouse',
        title: 'Warehouse',
        to: '/auth/warehouse',
        scope: 'warehouse',
        hide_on_outsourcing: true,
        hide: process.env.VUE_APP_MODE !== 'fh',
      },
      {
        icon: 'mdi-airplane-takeoff',
        title: 'Shipments',
        to: '/auth/shipments',
        scope: 'shipments',
        hide: process.env.VUE_APP_MODE !== 'fh',
      },
      {
        icon: 'mdi-bell-badge',
        title: 'Blocks & Logs',
        to: '/auth/logs',
        scope: 'logs',
        hide: process.env.VUE_APP_MODE !== 'fh',
      },
      {
        icon: 'mdi-shield-lock-open',
        title: 'Plans',
        to: '/auth/plans',
        scope: 'plans',
      },
      {
        icon: 'mdi-cog',
        title: 'Settings',
        to: '/auth/settings',
        id: 'settings',
        scope: 'settings.*',
        hide: process.env.VUE_APP_MODE !== 'postnord',
      },
      {
        icon: 'mdi-cog',
        title: 'Settings',
        to: '/auth/settings',
        id: 'settings',
        scope: 'settings.*',
        hide: process.env.VUE_APP_MODE !== 'fh',
        children: [
          {
            icon: 'mdi-warehouse',
            title: 'Fulfillment & Products',
            to: '/auth/settings/company',
            scope: 'settings.company',
            hide_on_outsourcing: true,
            hide: process.env.VUE_APP_MODE !== 'fh',
          },
          {
            icon: 'mdi-store',
            title: 'Stores & Shipping',
            to: '/auth/settings/stores',
            scope: 'settings.stores',
            hide: process.env.VUE_APP_MODE !== 'fh',
          },
          {
            icon: 'mdi-truck',
            title: 'Carriers',
            to: '/auth/settings/carriers',
            scope: 'settings.carriers',
            hide_on_outsourcing: true,
            hide: process.env.VUE_APP_MODE !== 'fh',
          },
          {
            icon: 'mdi-printer-wireless',
            title: 'Printers',
            to: '/auth/settings/printers',
            hide_on_outsourcing: true,
            scope: 'settings.printers'
          },
          {
            icon: 'mdi-account-multiple',
            title: 'Users',
            to: '/auth/settings/users',
            hide_on_outsourcing: true,
            scope: 'settings.users',
            hide: process.env.VUE_APP_MODE !== 'fh',
          },
        ]
      },
    ],
  }),

  computed: {
    drawer: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.$store.commit('app/SET_DRAWER', val)
      },
    },
    computedItems () {
      return this.items
        .filter(i => {
          if(i.hide) return false
          else if(i.hide_on_outsourcing && !this.is_fulfiller) return false
          return true 
        })
        .map(this.mapItem)
    },
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_user_scope: 'user/get_user_scope',
      is_fulfiller: 'app/is_fulfiller',
    })
  },

  methods: {
    filterItem(item) {
      return item.scope ? this.get_user_scope(item.scope) : true
    },
    mapItem (item) {
      return {
        ...item,
        disabled: item.scope ? !this.get_user_scope(item.scope) : false,
        children: item.children ? item.children
          .filter(i => {
            if(i.hide) return false
            else if(i.hide_on_outsourcing && !this.is_fulfiller) return false
            return true 
          })
          .map(this.mapItem)
          : undefined,
        warning: this.get_printing_possible && !this.$store.state.warehouse.company.print_server_connected && item.id === 'settings',
      }
    },
  },
  mounted(){
    console.log(this.$router.matcher.getRoutes());
  }
}
</script>

<style lang="scss" scoped>
.core-navigation-drawer {
  padding-bottom: env(safe-area-inset-bottom) !important;
  background: #ffffff !important;
  
  .drawer-header {
    padding: 16px 12px;
    background: linear-gradient(135deg, var(--v-primary-base) 0%, var(--v-primary-darken1) 100%);
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
    margin-bottom: 4px;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: 
        radial-gradient(circle at 90% 10%, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0) 60%),
        radial-gradient(circle at 10% 90%, rgba(255,255,255,0.08) 0%, rgba(255,255,255,0) 60%);
      pointer-events: none;
    }
    
    .logo-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      z-index: 1;
      
      .logo-image {
        filter: drop-shadow(0 2px 4px rgba(0,0,0,0.15));
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        margin-right: 8px;
        
        &:hover {
          transform: scale(1.05) rotate(2deg);
          filter: drop-shadow(0 3px 6px rgba(0,0,0,0.2));
        }
      }
      
      .app-name {
        font-size: 1.1rem;
        font-weight: 700;
        color: white;
        margin: 0;
        letter-spacing: 0.3px;
        text-shadow: 0 1px 2px rgba(0,0,0,0.15);
        white-space: nowrap;
      }
    }
  }
  
  .drawer-list {
    padding: 4px 8px;
    
    .menu-section {
      margin-bottom: 4px;
    }
    
    .menu-item,
    .menu-group {
      border-radius: 6px;
      margin: 2px 0;
      // min-height: 40px !important;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
      position: relative;
      overflow: hidden;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: var(--v-primary-base);
        opacity: 0;
        transition: opacity 0.25s ease;
      }
      
      &.v-list-item--active {
        background-color: rgba(var(--v-primary-base-rgb), 0.08) !important;
        
        &::before {
          opacity: 1;
        }
        
        .v-icon {
          color: var(--v-primary-base) !important;
        }
        
        .menu-title {
          color: var(--v-primary-base) !important;
          font-weight: 600;
          letter-spacing: 0.2px;
        }
      }
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        transform: translateX(2px);
      }
    }
    
    .menu-child-item {
      border-radius: 4px;
      margin: 1px 0 1px 6px;
      // min-height: 36px !important;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
      padding-left: 12px !important;
      
      &.v-list-item--active {
        background-color: rgba(var(--v-primary-base-rgb), 0.06) !important;
        
        .v-icon {
          color: var(--v-primary-base) !important;
        }
        
        .menu-child-title {
          color: var(--v-primary-base) !important;
          font-weight: 600;
        }
      }
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
        transform: translateX(2px);
      }
    }
    
    .menu-title {
      font-size: 0.85rem;
      font-weight: 500;
      letter-spacing: 0.1px;
      transition: all 0.25s ease;
      line-height: 1.2;
    }
    
    .menu-child-title {
      font-size: 0.8rem;
      font-weight: 400;
      letter-spacing: 0.1px;
      transition: all 0.25s ease;
      line-height: 1.2;
    }
    
    .v-list-group__header {
      padding-left: 12px;
      // min-height: 40px !important;
      
      &.v-list-item--active {
        &::before {
          opacity: 1;
        }
      }
    }
    
    .v-list-item__icon {
      margin-right: 12px !important;
      // margin-top: 6px !important;
      // margin-bottom: 6px !important;
      
      .v-icon {
        transition: all 0.25s ease;
        font-size: 20px !important;
      }
    }
  }
  
  .drawer-footer {
    padding: 12px 16px;
    
    .copyright {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
      font-size: 0.7rem;
      opacity: 0.7;
      transition: opacity 0.2s ease;
      
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
